<template>
  <v-container >

    <v-row  row fill-height>
      <v-snackbar
        v-model="snackbar"
        :timeout="3000"
        top
        color="info" dark
      >
        {{msg}}
        <v-btn
          color="white"
          text
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </v-snackbar>

      <v-progress-linear
        v-if="loading"
        class="ma-0 pa-0"
        :indeterminate="loading"
        color="info"
        reverse
        stream
      ></v-progress-linear>

      <!-- CATEGORIA//LINEA  -->
      <v-col cols="12" xs="12" >
        <v-card-title primary-title class="ma-0 pa-0">

          <v-breadcrumbs
            color="primary"
            :items="values"
            @click="goTo(item.to)"
            divider="/"
            class="pa-0">

            <template  v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>

          <!-- getCliente {{ getCliente}} Precio Util {{ precioutil}} -->
          <v-spacer></v-spacer>
          <v-btn text small class="mx-2" color="success">Hay {{paginacion}} páginas</v-btn>

          <p class="text--body-2"> </p>
          <v-btn text small class="mx-2" color="success">Precios incluyen iva </v-btn>

          <v-btn small class="mx-2" color="info" @click="regresar"><v-icon>reply</v-icon></v-btn>
        </v-card-title>
      </v-col>

      <!-- //CARGAR EL CLIENTE EN MODO VENDEDOR -->
      <v-col cols="12" xs="12" v-if="getModo === 'vendedor'">
        <v-card-text>
          <v-autocomplete
            @keyup.enter="submit"
            label="Selecciona un cliente"
            v-model="cliente"
            :items="clintesArr"
            clearable
            append-icon="search"
            @input="evento(cliente)"
          ></v-autocomplete>
        </v-card-text>
      </v-col>

      <!-- LINEA DE BUSQUEDA   -->
      <v-col cols="12" xs="12"
        v-if ="overlay">
        <v-progress-linear
          indeterminate
          color="red darken-2"
        ></v-progress-linear>
      </v-col>

      <!-- <v-col cols="6" xl="2" lg="2" md="2" sm="4" xs="4" -->
      <!-- MOSTRAR ARTICULOS POR LINEA -->
      <v-col cols="6" xl="3" lg="3" md="4" sm="4" xs="6"

        class="pa-1"
        v-else
        v-for="(artsxli, i) in getArticulosxLinea" :key="i">

        <v-hover>
          <v-card
            class="pa-0 ma-0"
            outlined
            elevation-5
            slot-scope="{ hover }"
            :class="`elevation-${hover ? 12 : 2}`"
            >

            <v-img
              v-if="artsxli.img == ''"
              class="pa-1 ma-1"
              :src="logourl"
              alt="Sin imagen"
              contain @click="info(artsxli.numart)"
              >
            </v-img>

            <v-img
              v-else
              class="pa-1 ma-1"
              :src="artsxli.img"
              alt="Foto"
              contain @click="info(artsxli.numart)"
              height="180"
              >
            </v-img>

            <colorescard  :title= artsxli.clave></colorescard>

            <!-- CLAVE Y DESCRIPCION -->
            <v-card-text class="py-0 ma-0 " @click="info(artsxli.numart)" style="height: 80px;">
              <v-list-item-content class=" text--darken-2" >

                <div class="text-xs-left red--text">
                  <h4>{{ artsxli.clave }}</h4>
                </div>
                <!-- <h5>{{ artsxli.details }}</h5> -->

                   <h5 v-if="artsxli.details.length > 80">{{ artsxli.details.substr(0, 80) }}</h5>
                  <h5 v-else>{{ artsxli.details}}</h5>

              </v-list-item-content>

              <h3 class="primary--text" v-if="artsxli.pjedesc > 0.00" ><del>$ {{ artsxli.preciopub }}</del> </h3>
              <h3 class="primary--text text-center" v-else>$ {{ artsxli.preciopub }} </h3>

                <v-spacer></v-spacer>

              <h3 class="red--text text-center" v-if= "artsxli.pjedesc> 0.00">  $ {{ artsxli.preciobajo}} </h3>

            </v-card-text>

            <!-- Comprar -->
            <v-card-actions class="mt-2">
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="validar(artsxli)"  fab x-small >
                <v-icon >
                  add_shopping_cart
                </v-icon>
              </v-btn>

            </v-card-actions>

          </v-card>
        </v-hover>

      </v-col>
    </v-row>

    <!-- PAGINACION NO VA EN PRODUCTOS INSIGNIA-->
      <v-row justify="center">
        <v-col cols="8">
          <v-container class="max-width">
            <v-pagination
              v-model="page"
              class="my-4"
              :length="paginacion"
              @input="masproductos"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>

    <v-btn
      fab
      color="primary" class="white--text mb-12"
      @click="arriba"
      fixed
      bottom
      right
      >
      <v-icon>keyboard_arrow_up</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import colorescard from '@/views/articulos/colorescard'

var accounting = require('accounting')
// import DescripArt from '@/views/articulos/DescripArt.vue'

export default {
  components: {
    colorescard
  },
  data: () => ({
    page: 1,
    paginacion: 1,
    loading: false,
    hislinea: '',
    precioutil: '',

    artxlinea: [],
    snackbar: false,
    msg: '',
    show: false,
    readonly: false,
    items: [],
    cliente: '',
    clintesArr: [],
    search: '',
    clientesAll: [],
    overlay: false,
    colors: [
      'primary',
      'secondary',
      'yellow darken-2',
      'red'
    ],
    logourl: '',
    values: [],

    tipoclase: 'linea',
    Envio: {
      cantidad: 1,
      clave: 'ENVIO',
      descrip: 'ENVIO A DOMICILIO',
      descuento: 0,
      details: 'ENVIO A DOMICILIO',
      divisa: 'P',
      estatus: '1',
      existencia: '0',
      img: '',
      importe: '1.00',
      impuesto: 0,
      impuesto1: 0,
      iva: 0.0,
      marca: '',
      modelo: '',
      numart: '               ENVIO',
      pjedesc: 0,
      precio: '1.00',
      preciobajo: '1.',
      preciopub: '1.',
      subtotal: 1.00,
      text: '',
      unidad: 'SERVI',
      url: ''
    }
  }),

  created () {
    console.log('artlinea router wathc', this.$route)

    this.precioutil = this.getConfig.precioutil
    this.init()
  },

  watch: {
    $route () {
      console.log('artlinea router wat', this.$route)
      this.paginacion = 1

      this.traerTC().then(response => {
        const numlin = this.$route.params.linea
        this.numlin = numlin
        this.getHistLinea(numlin)

        const categoria = this.$route.params.categoria

        if (categoria !== undefined) {
          console.log('CATEGORIA ')
          this.tipoclase = 'categoria'
        }

        if (numlin !== undefined) {
          console.log('LINEAS')
          this.tipoclase = 'linea'
        }

        if (numlin !== undefined && this.usarInsignias == '1') {
          console.log('LINEAS INSIGNIAS')
          this.tipoclase = 'linea.insignia'
        }

        if (categoria !== undefined && this.usarInsignias == '1') {
          console.log('Categorias INSIGNIAS')
          this.tipoclase = 'categoria.insignia'
        }

        this.loading = true

        // let numlin = this.$route.params.linea
        var payloadTipo = {
          divisa: this.$store.state.tipomoneda,
          numlin: numlin,
          categoria: categoria,
          tc: this.getTC,
          precioutil: this.getConfig.precioutil,
          tipoclase: this.tipoclase,
          page: (this.page - 1) * 20
        }

        console.log('payloadTipo ROUTER', payloadTipo)
        this.traerArticulosxLinea(payloadTipo).then(response => {
          this.loading = false
          this.overlay = false
          // this.page = getArticulosxLinea.Total/20
          // console.log("response", response)

          // console.log("page", response[0].nTotal/20)
          this.paginacion = parseInt(response[0].nTotal / 20)
        }).catch(error => {
          console.log(error)
        })
      })
    }
  },

  computed: {
    ...mapGetters('carrito', ['getCarrito', 'getTC']),
    ...mapGetters('articulos', ['getArticulosxLinea']),
    ...mapGetters('Login', ['getdatosUsuario', 'getInfoVend', 'getModo', 'getClientes', 'getCliente', 'getLogeado']),
    ...mapGetters('tema', ['getLogourl']),
    ...mapGetters('config', ['getConfig'])

  },

  methods: {
    ...mapActions('carrito', ['carritoadd', 'traerTC']),
    ...mapActions('articulos', ['traerArticulosxLinea', 'SetArticulosxLineaPaginados']),

    init () {
      this.loading = true
      console.log('cliente', this.getCliente)
      // console.log("getLogourl", this.getLogourl)
      this.logourl = this.getLogourl

      this.precioutil = this.getConfig.precioutil

      // determinar el uso de insignias..
      if (this.getConfig.preciosnetos == '1') {
        this.usarInsignias = true
      }

      // GET CLIENTE
      if (this.getCliente != '') {
        this.precioutil = this.getCliente.precioutil
        // Validar si es cleinte del vendedor
      } else {
        // NO HAY CLIENTE: PRECIO !.
        this.precioutil = this.getConfig.precioutil
      }

      // if(this.getClientes != ''){
      //   this.precioutil = this.getClientes.precioutil
      // }

      if (this.getCliente.nomcli != undefined) {
        this.clintesArr.push(this.getCliente.nomcli)
        this.cliente = this.getCliente.nomcli
      }

      // ANALISIS DE ROUTER A CARGAR
      console.log('router ', this.$route.params)
      const numlin = this.$route.params.linea
      const categoria = this.$route.params.categoria

      this.getHistLinea(numlin)

      if (categoria !== undefined) {
        console.log('CATEGORIA ')
        this.tipoclase = 'categoria'
      }
      // else {
      //   console.log("LINEAS")
      //   this.tipoclase = "linea"
      // }
      if (numlin !== undefined) {
        // console.log("LINEAS")
        this.tipoclase = 'linea'
      }

      if (numlin !== undefined && this.usarInsignias == '1') {
        console.log('LINEAS INSIGNIAS')
        this.tipoclase = 'linea.insignia'
      }

      if (categoria !== undefined && this.usarInsignias == '1') {
        console.log('Categorias INSIGNIAS')
        this.tipoclase = 'categoria.insignia'
      }

      console.log('tipoclase', this.tipoclase)

      this.traerTC().then(response => {
        // let numlin = this.$route.params.linea
        const payloadTipo = {
          divisa: this.$store.state.tipomoneda,
          numlin: numlin,
          categoria: categoria,
          tc: this.getTC,
          precioutil: this.getConfig.precioutil,
          tipoclase: this.tipoclase,
          page: (this.page - 1) * 18
        }

        console.log('payloatipo', payloadTipo)
        this.traerArticulosxLinea(payloadTipo).then(response => {
          this.loading = false

          if (response.length == 0) {
            this.msg = 'No hay articulos para mostrar'
            this.snackbar = true
          }

          console.log('page', response[0].nTotal / 20)

          this.paginacion = 1 + parseInt(response[0].nTotal / 20)
        }).catch(err => { console.log(err) })
      }).catch(err => { console.log(err) })
    },

    getHistLinea (numlin) {
      // console.log("GetHistLinea hislinea", numlin)
      // this.hislinea=[]
      this.values = []
      this.$http.get('api/v1/hislinea/' + numlin).then(response => {
        // console.log("OOOOOOOOOO    Historial por linea",response.data)

        this.hislinea = response.body
        this.values.push({ text: this.hislinea.Nomlin, disabled: false })
      }).catch(error => {
        console.log(error)
      })
    },

    goTo (payload) {
      // console.log("payload", payload)
      this.$router.to(payload)
    },

    regresar () {
      // console.log(this.hislinea)
      // this.$router.push({name: 'lineas', params:{id:this.hislinea.Numcat}}).catch(err => {console.log(err)})

      this.$router.go(-1)
    },

    validar (articulo) {
      if (this.getModo == 'vendedor') {
        if (this.$store.state.Login.cliente == '') {
          this.msg = 'Selecciona un cliente antes de agregar cualquier articulo'
          this.snackbar = true
        } else {
          this.agregar(articulo)
        }
        // modo pUBLICO
      } else {
        this.agregar(articulo)
      }
    },

    agregar (articulo) {
      articulo.cantidad = articulo.cantidad + 1
      this.carritoadd(articulo).then(response => {
        this.$store.state.carrito.carritovisible = true
      })

      // Agregar envio.
      this.Envio.cantidad = 1
      this.Envio.precio = 150

      this.carritoadd(this.Envio)
    },

    // ver art Insignia
    info (numart) {
      var NumArt = numart.trim()
      this.$router.push({ name: 'verartinsignia', params: { numart: NumArt } }).catch(err => { console.log(err) })
    },

    // carga los clientes
    submit () {
      this.clintesArr = []
      this.clientesAll = []
      var payload = { Buscacli: this.cliente }
      this.$http.post('auth/api/v1/clientes.find', payload).then(response => {
        this.clientesAll = response.body

        response.forEach((element) => {
          this.clintesArr.push(element)
        })
      }).catch(err => { console.log(err) })
    },

    // que chingados es evento
    evento (valor) {
      for (var i = this.clientesAll.length - 1; i >= 0; i--) {
        if (this.clientesAll[i].nomcli === valor) {
          this.$http.get('auth/api/v1/clientes/' + this.clientesAll[i].idweb).then(response => {
            this.updateCliente(response.body)
          }).catch(err => { console.log(err) })
        }
      }
    },

    arriba () {
      window.scrollTo(500, 0)
    },

    masproductos () {
      console.log('Mas producto', this.page)

      this.init()
    }

  }

}
</script>
