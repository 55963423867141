<template>
  <v-container fill-height grid-list-xl>
    <v-layout row wrap >
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12>
            <v-card>
              <v-card-title>
                Pedidos
              </v-card-title>

              <v-data-table
                :loading="loading"
                loading-text="Cargando..."
                :headers="headers"
                :items="Docum"
                :items-per-page="longitud"
                class="elevation-1"
                fixed-header
                height="440"
                :search="search"
                hide-default-footer
              >
                <template v-slot:item="{ item }" >
                 <tr v-on:dblclick="edit(item.Iddocum)">
                   <td>{{ item.Numdoc}}</td>
                   <td>{{ item.nomcli}}</td>
                   <!-- <td>{{ item.Tipodoc}}</td> -->
                   <td>{{ item.Fecha}}</td>
                   <td>{{ item.importe}}</td>
                   <td v-if=" item.divisa == 'P'">MXN</td>
                   <td v-else>USD</td>
                 </tr>
               </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
var accounting = require('accounting')
var base64 = require('base-64')
export default {
  data () {
    return {
      // FECHAS
      date1: new Date().toISOString().substr(0, 10),
      date2: new Date().toISOString().substr(0, 10),

      menu1: false,
      menu2: false,

      // SELECTOR TIPODOC
      tipodoc: ['Pedidos', 'Cotizaciones'],
      e1: 'Pedidos',
      loading: true,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: '',
      headers: [
        { text: 'ID doc', align: 'left', sortable: false, value: 'Numdoc' },
        { text: 'Nombre', value: 'Nomcli' },
        // { text: 'Tipo',      value: 'Tipodoc' },
        { text: 'Fecha', value: 'Fecha' },
        { text: 'Total', value: 'importe' },
        { text: 'Divisa', value: 'divisa' }
      ],

      Docum: [],
      longitud: 5
    }
  },

  computed: {
    ...mapGetters('Login', ['getdatosUsuario', 'getModo', 'getInfoVend'])
  },

  created () {
    // console.log("getInfoVend", this.getInfoVend)
    // Definir valores iniciales
    // Fecha inicial Primero de mes.
    const date0 = new Date()
    const mes = date0.getMonth()
    const anio = date0.getFullYear()

    // console.log(mes, anio)
    this.date1 = new Date(anio, mes, 1).toISOString().substr(0, 10)
    this.getInfoVend
    this.consultar()
  },

  methods: {
    // Consulto todos los clientes
    consultar () {
      this.loading = true
      var payload = {
        numvend: this.getInfoVend.Numvend,
        numcli: '',
        fechaini: '',
        fechafin: '',
        tipodoc: ' P'
      }

      console.log('payload en pedidosbynumvend', payload)

      this.$http.post('auth/api/v1/pedidosbynumvend', payload).then(response => {
        console.log('pedidosbynumvend', response.data)

        if (response.body.ntotal > 0) {
          response.data.Pedidos.forEach((element) => {
            element.importe = accounting.formatNumber(element.importe, 2)
          })
          this.Docum = response.body.Pedidos
          this.longitud = this.Docum.length
        }
      }).catch(function (error) {
        console.log(error)
      }).finally(() => this.loading = false)
    },

    // edit(tipodoc){
    //   var encodedData = base64.encode(tipodoc);
    //   this.$router.push({name:'documento', params: {tipodoc: encodedData}})
    // }
    edit (tipodoc) {
      // this.addDocumento(tipodoc)
      var encodedData = base64.encode(tipodoc)
      var doc = { doc: encodedData }
      this.$router.push({ name: 'documento', params: { info: doc, origen: 'pedido' } }).catch(err => { console.log(err) })
    }

  }
}
</script>
