<template>
  <v-row>
    <v-col cols="12" class="ma-0 pa-0" v-if="getArt.pdfs != ''">
      <v-card-title>
        Hoja técnica pdf
      </v-card-title>

       <v-card-text >
       <a :href="getArt.pdfs" target="_blank"> {{ getArt.pdfs  }}</a>
        <!-- <pdf  :src="getArt.pdfs"></pdf> -->
      </v-card-text>
    </v-col>

  </v-row>
</template>
<script>
// import pdf from 'vue-pdf'
// import pdfb from 'vue-pdf'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PieArts',
  // components: { pdf, pdfb },

  computed: {
    ...mapGetters('carrito', ['getTC', 'getCarrito']),
    ...mapGetters('articulos', ['getArt']),
    ...mapGetters('Login', ['getModo'])

  },

  created () {
    // this.pdf2link = this.getArt.Pdf2
    console.log(this.getArt)
  }

}
</script>
