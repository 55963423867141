<template>
  <v-container >
    <v-row
      class="fill-height"
      align="center"
      justify="center"
    >
      <template v-for="(producto, i) in productos">
        <v-col
          :key="i"
          cols="12"
          md="2"
          xm="2"
          sm="2"
        >
          <v-hover v-slot="{ hover }">
            <v-card

              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              color="transparent"
              @click="info(producto.Numart)"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">

                  <v-img
                    contain
                    v-bind="attrs"
                    v-on="on"
                    :src="producto.Url"
                    height="100px"
                  >
                    <v-card-title class="text-h6 white--text">
                      <v-row
                        class="fill-height flex-column"
                        justify="space-between"
                      >
                       <!--  <p class="mt-4 subheading text-left">
                          {{ producto.Descrip }}
                        </p>

                        <div>
                          <p class="ma-0 text-body-1 font-weight-bold font-italic text-left">
                            {{ producto.Numart}}
                          </p>
                          <p class="text-caption font-weight-medium font-italic text-left">
                            {{ producto.subtext }}
                          </p>
                        </div> -->

                      </v-row>
                    </v-card-title>
                  </v-img>

                </template>
                 <span>{{producto.Descrip}}</span>
              </v-tooltip>

            </v-card>
          </v-hover>
        </v-col>
      </template>
    </v-row>

  </v-container>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'coloresgrid',
  data () {
    return {
      transparent: 'rgba(255, 255, 255, 0)',
      // icons: ['mdi-rewind', 'mdi-play', 'mdi-fast-forward'],
      loading: false,
      slider: null,
      productos: [],
      mostrarColores: false,
      numartActual: '',
      show: false
    }
  },

  // watch:{
  //   getArt()
  // },
  // Consultar Articulos por Familia.   /api/v1/arts.by.familia/371

  created () {
    this.numartActual = this.$route.params.numart.trim()
    this.init()
  },

  computed: {
    ...mapGetters('articulos', ['getArt'])
  },

  methods: {

    info (numart) {
      // console.log(numart)
      var NumArt = numart.trim()
      this.$router.push({ name: 'verartinsignia', params: { numart: NumArt } }).catch(err => { console.log(err) })
    },

    init () {
      // Traer la Familia del articulo
      const payload = { numart: this.numartActual }
      this.$http.post('api/v1/articulos.info', payload).then(response => {
      // this.$http.get('api/v1/product.info/' + this.numartActual).then(response=>{
        // console.log(response)
        this.familia = response.data.Familia
        if (response.data.Familia.trim() == '') {
          return
        }

        // Traer los productos de la familia (color)
        this.$http.get('api/v1/arts.by.familia/' + this.familia.trim()).then(response => {
          console.log('mostrarColores', response)

          if (response.data.length > 0) {
            this.productos = response.data
            this.mostrarColores = true
          }
        }).catch(error => {
          console.log(error)
        })
      }).catch(error => {
        console.log(error)
      })
    }
  }

}
</script>

<style scoped>
.v-card {
  transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.9;
 }

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>
