<template>
  <v-container>
    <v-row >
      <!-- AVISO TEMPORAL  -->
      <v-snackbar
        v-model="snackbar"
        :timeout="8000"
        top
        :color="color"
        >
        {{text}}
        <v-btn
          color="white"
          text
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </v-snackbar>
      <v-col>
        <v-card class="transparent mb-4 elevation-0">
          <!-- alerta -->
          <v-row v-if="estatus == false">
            <v-col cols="12">
              <v-alert
                outlined
                :type="type"
                prominent
                border="left"
              >
                {{ msg }}
              </v-alert>
            </v-col>
          </v-row>

          <!-- ingredar el rfc -->
          <v-row class="pb-0 " v-if="estatus == false">
            <v-col cols="12" xl="3" lg="3" md="4" sm="5" xs="6" class="pb-0 ">
              <v-card-text class="pb-0 ">
                <v-text-field
                  outlined
                  dense
                  label="Ingresa tu rfc"
                  v-model="rfc"
                  @keyup.enter="buscarRfc(rfc)"
                ></v-text-field>
              </v-card-text>
            </v-col>
            <v-col cols="12" xl="2" lg="1" md="3" sm="3" xs="3">
              <v-btn color="primary" @click="buscarRfc(rfc)"> <v-icon>search</v-icon></v-btn>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="3" sm="3" xs="3">
              <v-btn color="success" @click="actualizaUsuario()">Ligar cliente</v-btn>
            </v-col>
          </v-row>

          <!-- ENCABEZADOS -->
          <v-row  class="mb-3">
            <v-col cols="12" class="py-0">

            </v-col>
            <v-col cols="12">
              <v-card-title primary-title>
                Datos Fiscales <v-spacer></v-spacer>
                <v-btn
                  small
                  dark
                  class="ma-2"
                  @click.prevent="validaInfo"
                  color="blue darken-3">Grabar
                </v-btn>
              </v-card-title>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Nombre del Cliente"
                v-model ="cliente.nomcli"
                validate-on-blur
                class="pa-0"
                filled outlined dense   hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                label="Nombre comercial"
                v-model ="cliente.nomcomer"
                class="pa-0"
                outlined dense auto-grow hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mb-3">
            <v-col cols="12" xl="3" lg="3" md="4" sm="5" xs="6">
              <v-text-field
              label="Calle"
              dense
              v-model="cliente.Calle"
              outlined hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="3" sm="3" xs="3">
              <v-text-field
              label="Número exterior"
              dense
              v-model="cliente.Numext"
              outlined hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="3" sm="3" xs="3">
              <v-text-field
              label="Número Interior"
              dense
              v-model="cliente.Numint"
              outlined hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="4" sm="4" xs="5">
              <v-text-field
              label="Colonia"
              dense
              v-model="cliente.colonia"
              outlined hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="3" sm="3" xs="3">
              <v-text-field
              label="Ciudad"
              dense
              v-model="cliente.Ciudad"
              outlined hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="3" sm="3" xs="3">
              <v-text-field
              label="Estado"
              dense
              v-model="cliente.Estado"
              outlined hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="3" sm="3" xs="3">
              <v-text-field
              label="Pais"
              dense
              v-model="cliente.Pais"
              outlined hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="3" sm="3" xs="3">
              <v-text-field
              label="Cp"
              dense
              v-model="cliente.Cp"
              outlined hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="3" sm="3" xs="3">
              <v-text-field
              label="RFC"
              dense
              v-model="cliente.Rfc"
              outlined hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="3" sm="3" xs="3">
            </v-col>

          </v-row>
          <v-divider></v-divider>
          <v-row class="mb-3">
            <v-col cols="12">
              <v-card-title primary-title>
              Contacto
            </v-card-title>
            </v-col>

            <v-col cols="12" xl="3" lg="3" md="4" sm="5" xs="6">
              <v-text-field
                label="Email"
                dense
                outlined hide-details
                v-model="cliente.email1"
                color="#8CC642"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="4" sm="4" xs="6">
              <v-text-field
                label="Telefono"
                dense
                outlined hide-details
                v-model="cliente.Telefono1"
                color="#8CC642"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      estatus: false,
      rfc: '',
      // modo
      // 1-. Vendedor, 2-. Cliente, 3-. Usuario
      modo: '',

      cliente: '',
      e1: 0,
      text: '',
      snackbar: false,
      color: '',

      // Variables
      Nomcomer: '',

      Puesto: '',
      Telefono1: '',

      Atpagos: '',
      Email2: '',
      Emailfac: '',
      Telefono2: '',

      Obs: '',

      type: 'warning',
      msg: ''

    }
  },

  created () {
    this.init()
  },

  computed: {
    ...mapGetters('carrito', ['getCarrito', 'getTC']),
    ...mapGetters('Login', ['getdatosUsuario', 'getClientes', 'getCliente', 'getCIA', 'getInfoVend', 'getModo'])
  },

  methods: {
    ...mapActions('Login', ['actualizaCliente']),

    init () {
      // modo cliente
      if (this.getClientes.length > 0) {
        this.modo = 2
        this.cliente = this.getClientes
        this.estatus = true
      } else {
        this.type = 'info'
        this.msg = 'Tu usuario no esta ligado a ningún cliente, por favor ingresa tu rfc y después da click en ligar cliente en caso de que los datos sean correctos, en caso de que no se encuentren datos, solo debes llenar los campos que a continuación se presentan y dar click en grabar.'
        this.modo = 3
      }
    },

    buscarRfc (rfc) {
      this.$http.get('auth/api/v1/clientebyrfc/' + rfc).then(response => {
        this.cliente = response.body
      }).catch(error => {
        this.color = 'warning'
        this.text = 'No se encontro ningun cliente con ese RFC'
        this.snackbar = true
      })
    },

    validaInfo () {
      const payload = {
        Nomcli: this.cliente.nomcli,
        Nomcomer: this.cliente.nomcomer,
        Calle: this.cliente.Calle,
        Numext: this.cliente.Numext,
        Numint: this.cliente.Numint,
        Colonia: this.cliente.colonia,
        Ciudad: this.cliente.Ciudad,
        Estado: this.cliente.Estado,
        Pais: this.cliente.Pais,
        Cp: this.cliente.Cp,
        Rfc: this.cliente.Rfc,

        Atvent: this.cliente.atvent,
        Email1: this.cliente.email1,
        Telefono: this.cliente.Telefono,
        Numcli: ''
      }

      var me = this

      if (this.modo == 2) {
        this.updateCliente()
        return
      }

      if (this.modo == 3) {
        this.agregarCliente(payload)
      }
    },

    agregarCliente (payload) {
      // obtenemos el siguiente cliente
      this.$http.post('api/v1/lastnumcli').then(response => {
        // le agregamos al cliente que vamos a registrar su numero de cliente
        payload.Numcli = response.body.Numcli

        // si es modo usuario, le decimos que su numero de cliente será el que recibimos
        if (this.modo == 3) {
          this.cliente.numcli = response.body.Numcli
        }

        // Agregamos el cliente
        this.$http.post('auth/api/v1/clientes', payload).then(response => {
          // actualizamos el campo de cliente, del usuaario
          this.actualizaUsuario()
        }).catch(function (error) {
          console.log(error)
        })
      }).catch(function (error) {
        console.log(error)
      })
    },

    // updateCliente(){
    //   this.$http.put('auth/api/v1/clientes/' + this.getdatosUsuario.idusuariosweb, this.cliente).then(response=>{
    //     // this.actualizaCliente()
    //   }).catch(function(error){
    //     console.log(error.estatus)
    //   })
    // },

    actualizaUsuario () {
      this.getdatosUsuario.numcli = this.cliente.numcli
      var me = this
      // actualizamos el usuario con su nuevo cliente
      this.$http.put('auth/api/v1/usuarios/' + this.getdatosUsuario.idusuariosweb, this.getdatosUsuario).then(response => {
        // mandamos a llamar una función que nos trae los datos del nuevo cliente para guardarlo en el estate
        this.actualizaCliente(this.getdatosUsuario.numcli)
        // si es modo 3 lo regresamos al carrito
        if (this.modo == 3) {
          setTimeout(function () { me.$router.push({ name: 'carrito' }) }, 1000)
        }
        this.text = 'Datos actualizados'
        this.snackbar = true
        this.init()
      }).catch(function (error) {
        console.log(error)
      })
    }
  }
}
</script>
