<template>
  <v-container  v-if="mostrarColores">
    <v-card elevation="10" class="ma-0 pa-0">

      <v-row  v-if="allProducts && !loading">
        <v-slide-group v-model="slider" center-active class="my-2" show-arrows>
          <v-slide-item v-for="(product, index) in allProducts" :key="index">
            <v-hover v-slot="{ hover }">

              <v-card
                elevation="10"
                v-if="product"
                :class="{ 'elevation-cs': hover }"
                class="ma-2 pa-2"
                width="180"
                height="auto"
                center-active
                active-class="success"

                hover
                tile
                color="transparent"
                @click="info(product.Numart)"
                exact
              >
                <v-img height="125" width="100%" contain :src="product.Url">
                  <template #placeholder>
                    <v-row
                      class="fill-height ma-2"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                  <div
                    v-if="hover"
                    class="d-flex align-start justify-end fill-height pa-1"
                    data-aos="fade-down"
                    data-aos-duration="100"
                  >
                    <!-- <v-icon color="red">mdi-heart-outline</v-icon> -->

                    <v-tooltip
                      v-model="show"
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="primary">
                            mdi-heart-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ product.Descrip }}</span>
                    </v-tooltip>

                  </div>
                </v-img>
                <!-- <div
                  class="px-0 text-body-1 font-weight-medium custom-title-text fs-12 mt-2"
                  :class="{ 'primary--text': hover }"
                  v-text="product.Descrip"
                  />
                  <div class="d-flex align-center justify-space-between">
                    <div class="rating  d-flex">

                    </div>
                    <span class="caption red--text font-weight-medium fs-13"
                      >$ {{ product.Preciopub }}</span
                    >
                  </div>
                </div> -->
              </v-card>
            </v-hover>
          </v-slide-item>
        </v-slide-group>
      </v-row>

      <h3 v-if="title" class="text-center  font-weight-light">
        Colores adicionales
      </h3>

      <v-row v-if="loading">
        <v-col v-for="(i, ix) in 4" :key="ix" cols="3">
          <v-sheet v-if="loading" :color="`grey lighten-4`" class="rounded">
            <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>

    </v-card>

  </v-container>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'NewArrivals',
  props: {
    title: {
      default: true
    }
  },
  data () {
    return {
      loading: false,
      slider: null,
      allProducts: null,
      mostrarColores: false,
      numartActual: '',
      show: false
    }
  },

  // watch:{
  //   getArt()
  // },
  // Consultar Articulos por Familia.   /api/v1/arts.by.familia/371

  created () {
    this.numartActual = this.$route.params.numart.trim()
    this.init()
  },

  computed: {
    ...mapGetters('articulos', ['getArt'])
  },

  methods: {

    info (numart) {
      // console.log(numart)
      var NumArt = numart.trim()
      this.$router.push({ name: 'verart', params: { numart: NumArt } }).catch(err => { console.log(err) })
    },

    init () {
      // Traer la Familia del articulo
      const payload = { numart: this.numartActual }

      this.$http.post('api/v1/articulos.info', payload).then(response => {
        // console.log("response",  response.data.Familia)
        this.familia = response.data.Familia

        this.familia = response.data.Familia
        if (response.data.Familia.trim() == '') {
          this.mostrarColores = false
          return
        }
        // Traer los productos de la familia (color)
        this.$http.get('api/v1/arts.by.familia/' + this.familia.trim()).then(response => {
          // console.log(response)
          if (response.data.length > 0) {
            this.allProducts = response.data
            this.mostrarColores = true
          }
        }).catch(error => {
          console.log(error)
        })
      }).catch(error => {
        console.log(error)
      })
    }
  }

}
</script>

<style>
.custom-card-text {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}
.custom-title-text {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fs-13 {
  font-size: 13px !important;
}
</style>
