<template>
	<v-data-table
    :headers="headers"
    :items="articulos"
    class=" elevation-4 ma-1 grey lighten-2"
    hide-default-header
    hide-default-footer
    calculate-widths
    height="390"
  >
  	<template v-slot:item="{ item }">
      <td>
  			<v-hover class="ma-2">
          <v-card
            class="ma-0 pa-0"
          	width="200px"
            height="370px"
            slot-scope="{ hover }"
            :class="`elevation-${hover ? 24 : 6} mt-2`"
            @click="info(item.Numart.trim())"
            >

            <!-- ACTUAL -->
            <v-row class="ma-0 pa-1" align-center jutify-center wrap>
              <v-card-text class= "ma-0 pa-0">
                <v-img
                  v-if="item.Url == ''"
                  class="mx-1"
                  :src="logourl"
                  contain
                ></v-img>

                <v-img v-else
                  contain
                  class="mx-auto"
                  :src="item.Url"
                ></v-img>

              </v-card-text>

              <v-card-text class="py-1 ma-2">
              	<div class="blue--text" >
                  <h4>{{ item.Numart }}   </h4>

                </div>
                <v-list-item class="pa-1">
						      <v-list-item-content class="pa-1">
						        <h4>{{ item.Descrip.substr(0,150) }}</h4>
						      </v-list-item-content>
						    </v-list-item>
                <div>
                  <h4 class="primary--text " v-if="item.Pjedesc > 0.00" ><del>$ {{ item.Preciopub }}</del>
                    <v-spacer></v-spacer>
                     </h4>

                  <h4 class="primary--text text-center" v-else>$ {{ item.Preciopub }} </h4>

                  <v-spacer></v-spacer>

                  <h4 class="red--text text-center"
                    v-if="item.Pjedesc > 0.00"> ${{ item.preciobajo}}
                  </h4>
                </div>
              </v-card-text>

            </v-row>

          </v-card>
        </v-hover>
  		</td>
    </template>
    <template v-slot:top>
    	<v-toolbar flat color="white">
    		<v-spacer></v-spacer>
    		<v-toolbar-title><h3>Novedades</h3></v-toolbar-title>
    		<v-spacer></v-spacer>
    	</v-toolbar>
    </template>
  </v-data-table>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
var accounting = require('accounting')

export default {
  data: () => ({
    	headers: [
    		{ text: 'Calories', value: 'Numart' }
    	],
    	articulos: [],
    show: false,
    colors: [
      'primary',
      'secondary',
      'yellow darken-2',
      'red'
    ],
    logourl: '',
    precioutil: 1
  }),

  computed: {
    ...mapGetters('tema', ['getLogourl']),
    ...mapGetters('config', ['getPrecioutil'])
  },

  created () {
    this.init()
  },

  watch: {
    getPrecioutil () {
      this.precioutil = this.getPrecioutil
    }

  },

  methods: {
    // accounting.formatNumber(element.Precio1, 2),

    init () {
      this.logourl = this.getLogourl
      // console.log(this.getPrecioutil)

      this.precioutil = this.getPrecioutil
      // this.precioutil= "4"

      // console.log("novedades.precioutil", this.precioutil)
      // var url = this.$http.options.root.replace("tienda3", "fotossait/");
      var payload = { tipo: 1 }

      this.$http.get('api/v1/novedades.list').then(response => {
        if (response.data.length > 0) {
          // console.log("novedades.list",response.data)
          response.data.forEach(element => {
            const valorInicial = element.Precio1
            switch (this.precioutil) {
            case '1':
              element.Precio1 = element.Precio1
              break

            case '2':
              element.Precio1 = element.Precio2
              break

            case '3':
              element.Precio1 = element.Precio3
              break

            case '4':
              element.Precio1 = element.Precio4
              break

            case '5':
              element.Precio1 = element.Precio5
              break

            default:
              element.Precio1 = element.Precio1
              break
            }

            if (element.Precio1 == '') {
              element.Precio1 = valorInicial
            }

            element.Preciopub = element.Precio1 + (element.Precio1 * element.Impuesto1 / 100)
            // element.Preciopub = accounting.formatNumber(element.Preciopub, 2)

            // calculo de precio bajo
            var nPrecioPub = accounting.unformat(element.Preciopub)
            var nDescuento = element.Pjedesc
            var nPreciobajo = nPrecioPub * (1 - (nDescuento / 100))

            element.preciobajo = accounting.formatNumber(nPreciobajo, 2)
            element.Pjedesc = accounting.formatNumber(element.Pjedesc, 2)
            this.articulos.push(element)
          })

          this.loading = false
          // console.log("articulos",this.articulos)
          // this.articulos = response.body.Productos
        }
        // console.log(this.articulos)
      }).catch(err => { console.log(err) })

      // this.$http.post('api/v1/novedes.list', payload).then(response=>{
      //   if(response.body.ntotal > 0){
      //     console.log("novedades.list",response.body.Productos)
      //     response.body.Productos.forEach(element => {
      //       element.Preciopub = accounting.formatNumber(element.Preciopub, 2)

      //       //calculo de precio bajo
      //        var nPrecioPub = accounting.unformat( element.Preciopub)
      //        var nDescuento = element.Pjedesc
      //        var nPreciobajo = nPrecioPub * (1- (nDescuento/100 ) )
      //       element.preciobajo =accounting.formatNumber(nPreciobajo,2)

      //       this.articulos.push(element)
      //     })

      //     this.loading = false
      //     // console.log(this.articulos)
      //     // this.articulos = response.body.Productos
      //   }
      //   // console.log(this.articulos)
      // }).catch(err =>{ console.log(err)})
    },

    info (numart) {
      var NumArt = numart.trim()
      this.$router.push({ name: 'verart', params: { numart: NumArt } }).catch(err => { console.log(err) })
    }

  }

}
</script>
