<template >
  <v-container fluid>
    <v-row align-space-around justify-center row fill-height>
      <a name="arriba"></a>

      <!-- Flex para las categorías -->
      <v-col cols="12" xs="12">

        <v-card  class="transparent elevation-0">

          <v-breadcrumbs :items="items" @click="goTo(item.to)" :divider="divider" class="py-0">
            <template  v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>

          <v-row row wrap justify-space-around >

            <!-- <v-col cols="12" xs="6" sm="4" md="3" lg="3" xl="3" class="pa-3" -->

            <v-col cols="12" md="2" sm="3" class="pa-3"

               v-for="(cat, i) in categorias" :key="i">
              <v-hover v-slot:default="{ hover }">
                <v-card
                  height="100%"
                  @click="cargarArtxCat(cat)"
                  class="mx-auto"
                  color="grey lighten-4"
                >
                  <!-- <v-img
                    contain
                    :aspect-ratio="16/9"
                    :src="cat.img"
                  > -->
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out grey  v-card--reveal  white--text"
                        style="height: 100%;"
                      >

                      </div>
                    </v-expand-transition>

                  <v-footer class="primary white--text" align="center">
                    {{ cat.text }}
                  </v-footer>

                  <v-card-text>

                  </v-card-text>

                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-btn
        fab
        color="primary" class="white--text mb-10" href="#arriba"
        fixed
        bottom
        right
        >
        <v-icon>keyboard_arrow_up</v-icon>
      </v-btn>

    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      hisdepto: '',
      categoria: '',
      categorias: [],
      menu: false,
      items: [],
      overlay: false,
      divider: '/',
      usarInsignias: false,
      Numcat: ''

    }
  },

  created () {
    console.log('route', this.$route.params)
    var valdep = this.$route.params.id
    this.getHisDepto(valdep)
    this.categorias = []
    this.traerCategorias()
    this.traerusainsignias()
  },

  computed: {
    ...mapGetters('tema', ['getLogourl'])
  },

  watch: {
    $route () {
      this.categorias = []
      this.items = []
      var valdep = this.$route.params.id
      this.getHisDepto(valdep)
      this.traerCategorias()
      this.traerusainsignias()
    }
  },

  methods: {

    traerusainsignias () {
      this.$http.get('api/v1/sigfolios.list').then(response => {
        // console.log(response)
        this.precioutil = response.data.Precioutil
        this.preciosnetos = response.data.preciosnetos

        if (this.preciosnetos == '1') {
          this.usarInsignias = true
        }
      }, error => {
        console.log(error)
      })
    },

    getHisDepto (valdep) {
      this.$http.get('api/v1/hisdepto/' + valdep).then(response => {
        this.hisdepto = response.body
        // console.log('categoriay depto: ',this.hisdepto)
        this.items.push({ text: this.hisdepto.Nomdep, disabled: false, to: this.hisdepto.Valdep })
      }).catch(error => {
        console.log('error: ', error)
      })
    },

    traerCategorias () {
      var url = this.$http.options.root.replace('tienda3', 'fotossait/')

      var valdep = this.$route.params.id
      this.categoria = this.$route.params.text

      this.$http.get('api/v1/catego.listbyValdep/' + valdep).then(response => {
        console.log('cateogias por depto ', response.body)

        if (typeof (response.body) === 'object') {
          this.categorias = []

          // Cargar solo Categorias Activos
          response.body.forEach((element) => {
            // if(element.Imagen_name != ''){
            //   element.Imagen_name = url + element.Imagen_name
            // }

            if (element.Url == '') {
              element.Url = this.getLogourl
            }

            // console.log("traerCategorias",element)
            this.categorias.push({
              // Se va a usar url
              // img: element.Imagen_name,
              img: element.Url,
              text: element.Nomcat,
              numcat: element.Numcat,
              url: element.Url
            })
          })
        }
      }).catch(error => {
        console.log(error)
      })
    },

    traerLineas (valLinea) {
      console.log(valLinea)
      // Mandar a llamar los articulos con el numero de linea
      this.$router.push({
        name: 'lineas',
        params: {
          id: valLinea.numcat.trim()
        }
      })
    },

    // Cargar articulos por linea
    cargarArtxCat (itemnumcat) {
      // console.log("numcat", itemnumcat)

      // console.log("usarInsignias", this.usarInsignias)

      // this.Numcat = itemnumcat.numcat.trim()

      if (this.usarInsignias == true) {
        this.$router.push('/artcategoinsignia/' + itemnumcat.numcat.trim()
        ).catch(err => { console.log(err) })
      } else {
        this.$router.push({
          name: 'artlinea.catego',
          params: { categoria: itemnumcat.numcat.trim() }
        }).catch(err => { console.log(err) })
      }

      // Mandar a llamar los articulos con el numero de linea
      // var numLinea = numlin.trim() linea: numlin.numlin.trim(),
      // this.$router.push({name: 'artlinea', params: { linea: "CAT22", categoria: itemnumcat.numcat.trim()  }})
    }

  }
}

</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .8;
  position: absolute;
  width: 100%;
}

</style>
