<template>
  <v-container fill-height>
    <v-row >

      <v-col cols="12" xs="12" v-if="getArticulos == ''">
          <v-alert type="info" text border="right" icon="close" :value="true">
            No se encontraron productos.
          </v-alert>
      </v-col>

      <Leecliente v-if="show"></Leecliente>
<!--
      <v-col cols="12" xs="12" v-else-if="getModo === 'vendedor'">
        <v-card-text>
          <v-autocomplete
            @keyup.enter="submit"
            label="Selecciona un cliente"
            v-model="cliente"
            :hint="`${cliente.nomcli}, ${cliente.Numcli}`"
            :items="clintesArr"
            item-text="nomcli"
            clearable
            append-icon="search"
            @input="evento(cliente)"
          ></v-autocomplete>
        </v-card-text>
      </v-col>  -->

      <v-col cols="12" xs="12" v-if="temp_arts.length == 0">
        <v-progress-linear
          indeterminate
          color="info"
        ></v-progress-linear>
      </v-col>

      <!-- v-else -->

    </v-row>

    <v-row>
      <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12"
        v-for="(arts, i) in temp_arts"
        :key="i"
        >
        <v-hover>
          <v-card
            outlined
            height="100%"

            slot-scope="{ hover }"
            :class="`elevation-${hover ? 12 : 2}`"
            >

                <v-row class="ma-0 pa-0">
                  <v-col cols="12" xs="12" class="pa-0 ma-0">

                    <v-card
                      height= "140"
                      elevation="0"
                      >
                        <v-img class="ma-1 pa-1"
                          v-if="arts.img == ''"
                          :src="logourl"
                          height="140"
                          alt="Sin imagen"
                          @click="ver(arts.numart)"
                          contain
                        ></v-img>

                        <v-img v-else
                          :src="arts.img"
                          alt="foto"
                          height="140"
                          contain
                          @click="ver(arts.numart)"
                        ></v-img>

                    </v-card>

                        <!-- <v-img v-if="arts.img == ''"
                          :src="logourl"
                          max-heigth="100"
                          alt="Sin imagen"
                          aspect-radio ="1.4"
                          contain
                        ></v-img>

                        <v-img v-else
                          :src="arts.img"
                          max-heigth="100"
                          alt="foto"
                          aspect-radio ="1.4"
                          contain
                        ></v-img>
                       -->
                     <!--  </v-carousel-item>
                    </v-carousel> -->

                <v-card-text class="py-0 ma-0 " @click="ver(arts.numart)" style="height: 80px;">

                  <v-list-item-content class=" text--darken-2" >
                    <div class="text-xs-left red--text"><h3>{{ arts.clave }}</h3></div>
                    <div v-if="arts.pjedesc > 0"  class="text-xs-left red--text text--darken-2">
                      <strong>Descuento: </strong>{{ arts.pjedesc }}%
                    </div>
                    <h4 v-if="arts.details.length > 90">{{ arts.details.substr(0, 90) }}...</h4>
                    <h4 v-else>{{ arts.details}}</h4>
                  </v-list-item-content>
                </v-card-text>

                <v-card-actions class="pl-4">
                  <!-- Precio -->
                  <div v-if="arts.divisa == 'P'" class="red--text"><h4><strong>$ {{ arts.preciopub }} </strong></h4></div>
                  <div v-else class="red--text"><h4><strong>$ {{ arts.preciopub }}</strong></h4> </div>
                  <!-- Comprar -->
                  <v-spacer></v-spacer>
                  <v-btn color="success" @click="validar(arts)" fab small >
                    <v-icon center>
                      add_shopping_cart
                    </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-col>

            </v-row>
          </v-card>
        </v-hover>
      </v-col>

      <!-- <v-col xs12 v-if="temp_arts.length > 20">
        <v-card-text align="center">
          <v-btn color="primary" @click="mas">Cargar más</v-btn>
        </v-card-text>
      </v-col> -->

    </v-row>

    <v-btn
      fab
      color="primary" class="white--text mb-10"
      @click="arriba"
      fixed
      bottom
      right
      >
      <v-icon>keyboard_arrow_up</v-icon>
    </v-btn>

    <v-snackbar
        v-model="snackbar"
        :timeout="3000"
        top
        color="info" dark
      >
        {{msg}}
        <v-btn
          color="white"
          text
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>

      </v-snackbar>

  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import store from '@/store'
import Leecliente from '@/components/leecliente'
var accounting = require('accounting')

export default {
  components: {
    Leecliente
  },

  data: () => ({
    // url: http.options.root.replace("tienda3", "fotossait/"),
    precioutil: 0,
    overlay: false,
    show: false,
    items: [],
    cliente: { nomcli: '', Numcli: '' },
    clintesArr: [],
    search: '',
    clientesAll: [],
    colors: [
      'primary',
      'secondary',
      'yellow darken-2'
    ],
    msg: '',
    snackbar: false,

    temp_arts: [],
    contador: 20,

    usarInsignias: false
    // page: 1,
    // pageCount: 0,
    // itemsPerPage: 10,
  }),

  created () {
    // console.log(this.getCliente)
    // console.log(this.getArticulos)
    this.init()
  },

  watch: {
    getArticulos () {
      this.init()
    }

    // cliente(){
    //   // console.log(this.cliente)
    // }
  },

  computed: {
    ...mapGetters('carrito', ['getCarrito', 'getTC']),
    ...mapGetters('articulos', ['getArticulos']),
    ...mapGetters('Login', ['getModo', 'getCliente']),
    ...mapGetters('tema', ['getLogourl']),
    ...mapGetters('config', ['getConfig'])

  },
  methods: {
    ...mapActions('carrito', ['carritoadd', 'traerTC', 'limpiarCarrito']),
    ...mapActions('articulos', ['traerAllArticulos', 'bucarArt']),
    ...mapActions('Login', ['updateCliente']),

    // mas(){
    //   for (var i = this.contador + 20; i > this.contador; i--) {
    //     this.temp_arts.push(this.getArticulos[i])
    //   }
    //   this.contador += 20
    // },

    ver (NumArt) {
      // Si no usa insignias
      if (this.usarInsignias == false) {
        this.$router.push({ name: 'verart', params: { numart: NumArt.trim() } })
      }

      // Si no usa insignias
      if (this.usarInsignias == true) {
        this.$router.push({ name: 'verartinsignia', params: { numart: NumArt.trim() } })
      }
    },

    // validar(articulo){
    //   if(this.getModo == 'vendedor'){
    //     this.show= true

    //     if(this.getClientes == ''){
    //       this.msg = 'Selecciona un cliente antes de agregar cualquier articulo'
    //       this.snackbar = true
    //     }else{
    //       this.agregar(articulo)
    //     }
    //   }else{
    //     this.agregar(articulo)
    //   }
    // },

    // EN CASO de CAMBIAR DE CLIENTE.
    // console.log("evento buscando cliente",response.body)
    // this.updateCliente(response.body).then(response=>{
    //   this.init()
    // })
    // var value = []
    // this.limpiarCarrito(value)

    init () {
      // determinar el uso de insignias..
      if (this.getConfig.preciosnetos == '1') {
        this.usarInsignias = true
      }
      console.log('usarInsignias', this.usarInsignias)

      this.logourl = this.getLogourl
      this.contador = 20

      this.contador = this.getArticulos.length - 1
      this.temp_arts = []

      // if(this.getArticulos.length < this.contador){
      //   this.contador = this.getArticulos.length - 1
      // }

      for (var i = this.contador; i >= 0; i--) {
        this.temp_arts.push(this.getArticulos[i])
      }

      // console.log("temp_arts",this.temp_arts)
    },

    agregar (articulo) {
      articulo.cantidad = articulo.cantidad + 1

      this.carritoadd(articulo).then(response => {
        for (var i = this.getCarrito.length - 1; i >= 0; i--) {
          for (var j = this.items.length - 1; j >= 0; j--) {
            if (this.items[j].clave == this.getCarrito[i].clave) {
              this.items[j].cantidad = 1
            }
          }
        }
        this.$store.state.carrito.carritovisible = true
      })
    },

    arriba () {
      window.scrollTo(500, 0)
    }

  }

}
</script>
