<template>
  <v-card flat tile v-if="mostrarpromos"
  >
    <v-toolbar
        color="red darken-3"
        dark
        dense
        class="mt-2"
      >

      <v-toolbar-title>Promociones  </v-toolbar-title>
        <v-spacer></v-spacer>
      <!-- <div>23 de mayo al 31 de Mayo 2021</div> -->

    </v-toolbar>

    <v-container class="mt-2 red darken-2"
      fluid >
      <v-row>
        <v-col
          v-for="promo in promos"
          :key="promo.clave"
          cols="12"
          sm="4"
          md="3"
        >
          <v-card
            heigth="350"
            :to= "promo.to">

            <v-card-title class="ma-1 mx-3 pa-1 red--text">
              {{ promo.clave }}
            </v-card-title>

            <v-img
              :src= "promo.img"
              height="150px"
              contain
            >
              <span>
                <v-img
                  src="@/assets/hotsale.png"
                  height="50"
                  contain>
                </v-img>

              </span>
            </v-img>

            <v-card-text >
              <!-- <div class="blue--text" >
                <h4>{{ promo.clave }}</h4>
              </div> -->
              <v-list-item class="pa-0 ma-0">
                <v-list-item-content class="pa-0 pa-0">
                  <h4>{{ promo.details.substr(0,35)}}</h4>

                  <h5 justify="center" class=" pa-0 ma-0 ">$ <del> {{ promo.precio }} </del></h5>
                </v-list-item-content>
              </v-list-item>

              <!-- <div>
                <h4 class="primary--text " v-if="promo.pjedesc > 0.00" ><del>$ {{ promo.Preciopub }}</del> </h4>
                <h4 class="primary--text text-center" v-else>$ {{ promo.preciopub }} </h4>
                  <v-spacer></v-spacer>
                <h4 class="red--text text-center"v-if= "promo.pjedesc > 0.00">{{promo.preciopub}} </h4>
              </div> -->
            </v-card-text>

            <!-- <v-card-actions class="blue--text justify-center">
              <v-card-text >
                <v-textarea>{{ promo.details.substr(0,35)}}
                </v-textarea>

              </v-card-text>

            </v-card-actions>
 -->
            <v-card-actions class="red--text">
              <v-spacer></v-spacer>
              $ {{promo.preciopub}}
              <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</v-card>

    <!--         <v-card-title class="red--text">
                {{ item.clave }}
              </v-card-title>
            <v-row>
              <v-col cols="6" sm="6" class="py-0">

                <v-card-text class="pr-0">
                  <div class="text-xs-left "><strong> Precio: </strong></div>
                  <div class="text-xs-left " v-if="item.divisa == 'P'">{{ item.precio }}  MXN</div>
                  <div class="text-xs-left " v-else>{{ item.precio }}  USD</div>
                  <div class="text-xs-left " :if="item.marca != ''"><strong>Marca: </strong> {{ item.marca }}</div>
                  <div class="text-xs-left "><strong>Unidad: </strong> {{ item.unidad }}</div>
                  <div class="text-xs-left red--text text--darken-2"><strong> Oferta:{{ item.preciopub }} </strong></div>
                  <div class="green--text text--darken-2"><strong>Disponibles: </strong> {{ item.limite }}</div>
                </v-card-text>
              </v-col>

              <v-col cols="6" sm="6" class="py-0">
                <v-card-text class="pl-0">
                  <img v-if="item.img == ''" src="/blanco.jpg"  alt="Sin imagen"  width="98%" height="150" >
                  <img v-else :src="item.img" alt="Sin  imagen"  width="90%" height="150">
                </v-card-text>
              </v-col>

              <v-col cols="12" class="py-0">
                <v-card-text class="py-0">
                  <v-list-item-content class=" text--darken-2" >
                    <h4>{{ item.details.substr(0,150)}}...</h4>
                  </v-list-item-content>
                </v-card-text>
              </v-col>

              <v-col>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="success" @click="validar(item)" fab small >
                    <v-icon center>
                      add_shopping_cart
                    </v-icon>
                  </v-btn>

                  <v-btn color="info" @click="ver(item.numart)" fab small>
                    <v-icon center>
                      info
                    </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-col>

            </v-row>
          </v-card>
 -->
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import store from '@/store'
var accounting = require('accounting')

export default {
  data: () => ({
    mostrarpromos: false,
    headers: [
      { text: 'Calories', value: 'Numart' }
    ],
    promos: [],
    show: false,
    colors: [
      'primary',
      'secondary',
      'yellow darken-2',
      'red'
    ],
    overlay: false,

    show: false,
    show2: true,

    panel: [0, 1],
    readonly: false,

    items: [],
    cliente: '',

    msg: '',
    snackbar: false
  }),

  created () {
    this.init()
  },

  computed: {
    ...mapGetters('carrito', ['getCarrito', 'getTC']),
    ...mapGetters('articulos', ['getArticulos', 'getPromos']),
    ...mapGetters('Login', ['getModo'])

  },

  methods: {
    ...mapActions('carrito', ['carritoadd', 'traerTC', 'limpiarCarrito']),
    ...mapActions('articulos', ['traerAllArticulos', 'traerPromos']),
    ...mapActions('Login', ['updateCliente']),

    init () {
      // Consultar Promos
      this.loading = true
      this.traerTC().then(response => {
        var payload = { divisa: this.$store.state.tipomoneda, tc: this.getTC }
        this.traerPromos(payload).then(response => {
          if (this.getPromos.length > 0) {
            this.mostrarpromos = true
            console.log('entre', this.getPromos)
            this.promos = this.getPromos
          }
        })
      })
    },

    ver (NumArt) {
      this.$router.push({ name: 'verart', params: { numart: NumArt.trim() } })
    },

    validar (articulo) {
      if (this.getModo == 'vendedor') {
        if (this.$store.state.Login.cliente == '') {
          this.msg = 'Selecciona un cliente antes de agregar cualquier articulo'
          this.snackbar = true
        } else {
          this.agregar(articulo)
        }
      } else {
        this.agregar(articulo)
      }
    },

    agregar (articulo) {
      articulo.cantidad = articulo.cantidad + 1
      articulo.precio = articulo.preciopub

      // articulo.preciopub =
      this.carritoadd(articulo).then(response => {
        for (var i = this.getCarrito.length - 1; i >= 0; i--) {
          for (var j = this.items.length - 1; j >= 0; j--) {
            if (this.items[j].clave == this.getCarrito[i].clave) {
              this.items[j].cantidad = 1
            }
          }
        }
        this.$store.state.carrito.carritovisible = true
      })
    },

    // BUSCAR CLIENTE
    submit () {
      this.clintesArr = []
      this.clientesAll = []
      var payload = { Buscacli: this.cliente }
      this.$http.post('auth/api/v1/clientes.find', payload).then(response => {
        this.clientesAll = response.body
        for (var i = response.body.length - 1; i >= 0; i--) {
          this.clintesArr.push(response.body[i].nomcli)
        }
      })
    },

    evento (valor) {
      for (var i = this.clientesAll.length - 1; i >= 0; i--) {
        if (this.clientesAll[i].nomcli === valor) {
          this.$http.get('auth/api/v1/clientes/' + this.clientesAll[i].idweb).then(response => {
            this.updateCliente(response.body)
            var value = []
            this.limpiarCarrito(value)
          })
        }
      }
    }
  }

}
</script>
