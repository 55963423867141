import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'

import VueMeta from 'vue-meta'

import VueHtmlToPaper from 'vue-html-to-paper'

import VueResource from 'vue-resource'

// https://www.npmjs.com/package/vue-image-zoomer

// youtube
import VueYoutube from 'vue-youtube'
Vue.use(VueMeta)
// Vue.use(VueHtmlToPaper);

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  // styles: [
  //   'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
  //   'https://unpkg.com/kidlat-css/css/kidlat.css'
  // ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title // override the window title
}
Vue.use(VueHtmlToPaper, options)
Vue.use(VueYoutube)

// var accounting = require('accounting')

const moment = require('moment')
require('moment/locale/es')

Vue.use(require('vue-moment'), {
  moment
})

// console.log(Vue.moment().locale())
// Vue.use(require('vue-moment'));

Vue.config.productionTip = false
Vue.use(VueResource)

Vue.use(VueHtmlToPaper)

// Vue.http.options.root = 'http://localhost:8099/tienda3'
//   Vue.http.options.root = 'https://pruebasgo.mx/tienda3'

// Mikaishop
// Vue.http.options.root = 'https://mikaishop.com/tienda3'
// console.log(process.env.VUE_APP_OPTIONS_ROOT)
// console.log(process.env.BASE_URL)
// console.log(process.env.NODE_ENV)
// console.log(process.env.VUE_APP_TITLE)

// AUTOMATIZAR DESPLIEGUE POR EMPRESA

if (process.env.NODE_ENV === 'development') {
  Vue.http.options.root = 'http://localhost:8099/tienda3'

  // Vue.http.options.root = 'https://saiterp.com/acr/tienda3'
  // Vue.http.options.root = 'https://saiterp.com/cosmeticoszalie/tienda3'
  // Vue.http.options.root = 'https://tiendaenlinea.pro/tienda3'
  // Vue.http.options.root = 'http://localhost:8099/tienda3'
  // Vue.http.options.root = 'https://mikaishop.com/tienda3'
  // Vue.http.options.root = 'https://tiendaenlinea.pro/tienda3'
  // Vue.http.options.root = process.env.VUE_APP_OPTIONS_ROOT
} else {
  // Vue.http.options.root = 'https://mikaishop.com/tienda3'
  Vue.http.options.root = process.env.VUE_APP_OPTIONS_ROOT
}

// Vue.http.options.root = process.env.VUE_APP_OPTIONS_ROOT
// Vue.http.options.root = 'https://importadoragrupoavmx.com/tienda3'

// ANDROID APP
// Vue.http.options.root = 'https://saiterp.com/acr/tienda3'

// console.log(process.env.NODE_ENV)
// console.log(Vue.http.options.root)

// if (!loalStorage.getItem('capaKey')) {
//   localStorage.clear();
//   var tokenGen = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1NjIzMjkyMDUsImlkIjoibWFudWVsQHNhaXQubXgiLCJvcmlnX2lhdCI6MTU2MjMyNTYwNX0.-VEL2pk4ihCI9GTSMbye3AlzWjqCMUY4bMJqFmQZYVA';
//   localStorage.capaKey  = JSON.stringify(tokenGen);
// }

Vue.http.interceptors.push((request, next) => {
  // var token = JSON.parse(localStorage.tlkey)
  // console.log (token)
  request.headers.set('Authorization', 'Bearer ' + localStorage.tlkey)
  request.headers.set('Accept', 'application/json')
  next()
})

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
