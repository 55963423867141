<template>
  <v-row>
    <v-col  class="ma-0 pa-0" cols="12"
      v-if="getArt.pdf2 != ''">
      <v-card-title>
        Manual
      </v-card-title>

      <v-card-text >
       <a :href="getArt.pdf2" target="_blank">{{getArt.pdf2}}</a>
        <!-- <pdf  :src="getArt.pdf2"></pdf> -->
      </v-card-text>
    </v-col>
  </v-row>

</template>

<script>
// import pdf from 'vue-pdf'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PieArts',
  // components: { pdf },
  data () {
    return {
      pdf2link: ''

    }
  },

  computed: {
    ...mapGetters('carrito', ['getTC', 'getCarrito']),
    ...mapGetters('articulos', ['getArt']),
    ...mapGetters('Login', ['getModo'])

  },

  created () {
    console.log('pdf2', this.getArt)
    this.getArt.pdf2
  }

}
</script>
