<template>
  <v-container justify="center" class="ma-1 pa-1">

    <v-snackbar v-model="snackbar" top color="primary">
      {{msg}}
      <v-btn text  @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-row v-if ="cargando">
      <v-col cols="12">
       <v-skeleton-loader
          v-bind="attrs"
          type="list-item-avatar-three-line, image, article"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row justify="center"  v-else>

      <!-- Imagenes -->
      <v-col cols="12"  xl="4" lg="4" md="4" sm="4" xs="12" >
        <v-card  class="ma-0">

          <v-carousel
            cycle
            height="350"
            hide-delimiter-background
            show-arrows-on-hover
          >
            <v-carousel-item
              class="ma-1"
              v-for="(item,i) in items"
              :key="i"
              reverse-transition="fade-transition"
              transition="fade-transition"
            >
              <!-- <v-img
                v-if="item.src.length == 0"
                :src="logourl"
                alt="Sin imagen"
                contain
                heigth= "400"

              />

              <v-img
                v-else
                :src="item.src"
                alt="Sait"
                contain
                height= "400"
              /> -->
              <v-sheet
                height="100%"
                tile
              >
                  <v-row >
                    <v-col cols="12" md="12">
                      <image-zoom
                        class="center"
                        hover-message="zoom"
                        :regular="item.src"
                        :zoom="item.src"
                        :zoom-amount="3"
                        alt="ver detalle de producto">
                      </image-zoom>
                    </v-col>
                  </v-row>

              </v-sheet>

            </v-carousel-item>
          </v-carousel>
        </v-card>

      </v-col>

      <v-col cols="8">
        <v-row>
          <!-- CATEGORIA//LINEA  -->
<!--           <v-col cols="12"   >

              <v-breadcrumbs
                :items="values"
                @click="goTo(item.to)"
                divider="/"
                class="py-0">
                <template  v-slot:divider>
                  <v-icon>mdi-chevron-right</v-icon>
                </template>
              </v-breadcrumbs>

          </v-col>
 -->

          <!-- Detalles y mas -->
          <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">

            <v-card  height="100%" elevation="10" class="pa-2" >
              <p class="mx-3 font-weight-medium">
                 {{ nomart }}
              </p>

              <p class=" mx-3 red--text font-weight-medium">
                CODIGO: {{ getArt.numart }}
              </p>

                <div justify="center" class="primary--text mx-4 px-2"
                  v-if="getArt.pjedesc > 0.00" ><h3><del><strong>$ {{precioneto}} </strong></del></h3>
                </div>

                <div  justify="center" class="primary--text  mx-3"
                  v-else ><h4><strong>$ {{precioneto}} </strong></h4>
                </div>

                <div  justify="center" class="red--text pa-1" v-if="getArt.pjedesc > 0.00"><h3><strong>$ {{ preciobajo}} </strong></h3>
                </div>

            </v-card>
          </v-col>

          <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
             <v-card height="100%" elevation="10" class="pa-2" >

                <v-row align="center">

                  <!-- CANTIDAD -->
                  <v-col cols="5" >
                    <v-text-field

                      label="Cantidad"
                      v-model.number="cantidad"
                      type= "number"
                      :value="cantidad"
                      min="0"
                      class="pa-2 ma-2 font-weight-medium"
                      @change="cantManual"
                      ></v-text-field>
                  </v-col>

                  <!-- UNIDAD -->

                     <v-select v-if="selecionarunidad"
                        v-model="select"
                        :items="unidades"
                        item-text="unidad"
                        class="text-right"
                        label="Unidad"
                        prepend-icon="inbox"
                        persistent-hint
                        return-object
                        @change="PreciosxUnidad"
                      ></v-select>

                      <div  v-else >Unidad: {{unidad}}</div>

                      <!-- SOLO MOSTRAR EXISTENCIAS -->
                      <div
                        v-if="usaexist==='1'"
                        class="ma-1 pa-1" >Existencia: {{ existencia }}
                      </div>

                   </v-row>

                   <v-row class="ma-1">
                       <!-- BOTON MENOS -->
                      <v-btn x-small fab dark
                        class="mx-2"
                        color="orange "  @click="remove"><v-icon center>remove</v-icon></v-btn>

                      <!-- BOTON MAS -->
                      <v-btn  x-small fab dark color="success"  @click="add"><v-icon center>add</v-icon></v-btn>

                      <v-spacer></v-spacer>
                      <v-btn
                        color="secondary"
                        dark
                        small
                        @click="validar">Agregar
                        <v-icon right>shopping_cart</v-icon>
                      </v-btn>

                   </v-row>

              </v-card>

          </v-col>

        </v-row>

        <v-row>
          <v-card elevation="10">
          <coloresgrid></coloresgrid>
          </v-card>
        </v-row>

      </v-col>

    </v-row>

    <v-row>

      <v-col cols="12" md="8" v-if="videoId !=''">
        <v-card  color="primary" >

          <!-- <VideoArt></VideoArt> -->
          <v-row justify="center">
            <v-col cols="12"  v-if="videoId!=''">
              <v-card  class="elevation-0" height="100%" >

                <v-card-title primary-title  >
                  Video Id: {{ videoId}}  <v-btn class="mx-5" @click="playVideo" color="success">Play</v-btn>
                </v-card-title>

                <!-- v-if="videoId!=''" -->
                <v-card-text align="center" >
                  <youtube
                    height="400" width="80%"
                    :video-id="videoId"
                    ref="youtube"
                    @playing="playing" >
                  </youtube>
                </v-card-text>

              </v-card>
            </v-col>
          </v-row>

        </v-card>
      </v-col>
    </v-row>

     <v-row>
      <v-col cols="12" md="6">
        <Pdf/>
      </v-col>
      <v-col cols="12" md="6">
        <pdf2></pdf2>
      </v-col>
    </v-row>


    <v-btn
      fab
      color="secondary" class="white--text mb-10"
      @click="arriba"
      fixed
      bottom
      right
      x-small
      >
      <v-icon>keyboard_arrow_up</v-icon>
    </v-btn>

  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import coloresgrid from '@/views/articulos/coloresgrid'
import Pdf from '@/views/articulos/PieArt.vue'
import Pdf2 from '@/views/articulos/Pdf2.vue'
// import DescripArt from '@/views/articulos/DescripArt.vue'
import axios from 'axios'
// https://www.npmjs.com/package/vue-image-zoomer
import imageZoom from 'vue-image-zoomer'
var accounting = require('accounting')

export default {
  components: {
    Pdf,
    Pdf2,
    // DescripArt,
    coloresgrid,
    imageZoom
  },

  metaInfo () {
    return {
      title: this.getArt.numart,
      // override the parent template and just use the above title only
      // titleTemplate: null,
      meta: [
        { vmid: 'description', name: 'description', content: this.nomart },
        { property: 'og:site_name', content: 'Grupo Azalie' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://saiterp.com/b1.png' },
        { property: 'og:image:type', content: 'image/jpeg' },
        { property: 'og:image:width', content: '400' },
        { property: 'og:image:height', content: '300' },
        { property: 'og:image:alt', content: 'Mayorista en cosméticos' },
        { name: 'robots', content: 'index,follow' }
      ]
    }
  },

  data () {
    return {
      usaexist: '0',
      preciobajo: 0.00,
      existencia: 0.0,
      articulos: [],
      descrip: [],
      headers: [
        { text: 'Tipo', align: 'left', sortable: true, value: 'name' },
        { text: 'Valor', value: 'valor', sortable: true }
      ],

      items: [],
      detalles: '',
      nomart: '',
      tab: null,
      tabs: [],
      precioutil: '',
      snackbar: false,
      msg: '',
      obs: '',
      unidades: [{
        unidad: '',
        preciopub: 0.00,
        precio1: 0.00,
        numart: ''
      }
      ],
      unidad: '',
      cantidad: 1.00,
      selecionarunidad: false,
      select: { numart: '', unidad: '' },

      precioneto: 0.00,
      carrusel: [],
      tokenSait: '',
      hislinea: '',
      values: [],
      facebook: '',
      youtube: '',
      twitter: '',
      linkedin: '',
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2
      },
      cargando: true,

      videoId: '',
      video: '',
      tablacarac: [],
      logourl: ''
    }
  },

  created () {
    this.init()
    // console.log(this.$route.params.numart)

    if (process.env.VUE_APP_SAIT_NUBE != '') {
      console.log(' //LOGIN DE API SAITNUBE')

      var Payload = {
        numuser: process.env.VUE_APP_UN,
        password: process.env.VUE_APP_PW
      }

      // API AXIOS SAIT NUBE
      var url = process.env.VUE_APP_SAIT_NUBE
      var uri = url + '/api/v1/login'

      axios.post(uri, Payload, { headers: { 'Content-Type': 'application/json' } }).then(response => {
        // console.log("Sait NUBE",response.data)
        this.tokenSait = response.data.token
        // console.log("token Sait",this.tokenSait)
        localStorage.setItem('tokensait', this.tokenSait)
      }).catch(error => { console.log(error) })
    }

    this.arriba()
  },

  watch: {
    $route () {
      this.init()
      this.arriba()
    },

    getPrecioutil () {
      this.precioutil = this.getPrecioutil
    }

  },

  computed: {
    ...mapGetters('config', ['getPrecioutil', 'getConfig']),
    ...mapGetters('tema', ['getLogourl']),
    ...mapGetters('carrito', ['getTC', 'getCarrito']),
    ...mapGetters('articulos', ['getArt']),
    ...mapGetters('Login', ['getdatosUsuario', 'getInfoVend', 'getModo', 'getClientes', 'getCliente', 'getLogeado']),
    myCant: function () {
      return this.cantidad
    },
    player () {
      return this.$refs.youtube.player
    }
  },

  methods: {
    ...mapActions('articulos', ['verArt', 'traerArticulosxLinea']),
    ...mapActions('carrito', ['traerTC', 'carritoadd']),

    playVideo () {
      this.player.playVideo()
    },

    playing () {
      console.log('\o/ we are watching!!!')
    },

    getHistLinea (numlin) {
      // console.log("getHistLinea",numlin)

      this.values = []
      this.$http.get('api/v1/hislinea/' + numlin).then(response => {
        // console.log("hislinea",response.data)

        this.hislinea = response.body

        this.values.push({
          text: this.hislinea.Nomdep,
          disabled: false,
          to: '/categoria/' + this.hislinea.Valdep
        })

        this.values.push({
          text: this.hislinea.Nomcat,
          disabled: false,
          to: '/lineas/' + this.hislinea.Numcat
        })

        this.values.push({
          text: this.hislinea.Nomlin,
          disabled: false,
          to: '/artlinea/' + this.hislinea.Numlin
        })
      }).catch(error => {
        console.log(error)
      })
    },

    goTo (payload) {
      console.log('goto ', payload)

      this.$router.go(-1)
    },

    PreciosxUnidad () {
      // console.log(this.select.unidad, this.select.preciopub, this.select.precio1)
      this.precioneto = this.select.preciopub
      this.precio1 = this.select.precio1
    },

    cantManual (value) {
      this.cantidad = this.cantidad
    },

    init () {
      // console.log("INIT get art ",this.getArt)
      this.logourl = this.getLogourl

      this.usaexist = this.getConfig.usaexist

      this.precioutil = this.getPrecioutil
      // console.log("init verart", this.precioutil)

      this.getHistLinea(this.getArt.linea)
      const numart = this.$route.params.numart

      var url = process.env.VUE_APP_SAIT_NUBE
      var uri = url + '/api/v1/articulos/' + numart

      this.tokenSait = localStorage.getItem('tokensait')
      // console.log(uri, this.tokenSait)

      // axios.get(uri,{ headers: {'Content-Type': 'application/json', 'x-token': this.tokenSait }}).then(response =>  {
      //   // console.log("Sait NUBE",response.data)
      //   this.existencia = response.data.existencia
      // }).catch(error => {console.log(error) })

      // VALORES INICIALES
      this.tabs = []
      this.articulos = []

      // if(this.getArt.pdfs != '' || this.getArt.pdfs2 != ''){
      //   this.tabs.push('PDF')
      // }

      // console.log("VIDEO 2", this.getArt.video)
      // if(this.getArt.video != ''){
      //   this.tabs.push('Video')
      // }

      // this.tabs.push('Caracteristícas')
      // // console.log(this.tabs)

      // Validar si es cliente del usuario
      if (this.getCliente != '') {
        this.precioutil = this.getCliente.precioutil
        // Validar si es cleinte del vendedor
      } else if (this.getClientes != '') {
        this.precioutil = this.getClientes.precioutil
      }

      this.traerTC().then(response => {
        const numart = this.$route.params.numart

        var payload = {
          numart: numart,
          divisa: this.$store.state.tipomoneda,
          tc: this.getTC,
          precioutil: this.precioutil
        }

        var divisatienda = this.$store.state.tipomoneda
        var tctienda = this.getTC
        // SE manda precioutil en vacio.. ? por que chingados debe ser 1. Publico en general.

        // console.log("llamando a verArt",payload)

        this.verArt(payload).then(response => {
          // PROCESO PARA PONER valores en la vista
          this.traerArt()

          // console.log("VER ART verArt vuex", response)
          var cNumart = response.Numart.trim()
          this.unidad = response.Unidad
          // console.log(this.unidad)

          // console.log(this.getArt.fotos)
          this.carrusel = []
          this.carrusel2 = encodeURIComponent(this.getArt.fotos).split('%0D')

          var filtered = this.carrusel2.filter(function (el) {
            return el != ''
          })

          this.carrusel = filtered

          // console.log("Carrusel", encodeURIComponent(this.carrusel) )
          // console.log("CARRUSEL2", this.carrusel)

          // FORMAR UNIDADES
          this.unidades[0].unidad = this.unidad
          this.unidades[0].precio1 = accounting.formatNumber(response.Precio1, 2)
          this.unidades[0].preciopub = accounting.formatNumber(response.preciopub, 2)
          this.unidades[0].numart = response.Numart.trim()

          // console.log("getart1",this.getArt.impuesto1)
          this.impuesto1 = this.getArt.Impuesto1

          this.$http.get('api/v1/unidadesxart/' + cNumart).then(response => {
            // console.log("response.data unidadesxart",response.data)

            if (response.data.total > 0) {
              this.selecionarunidad = true
              // console.log("mostrar unidades",response.data.Rows)

              response.data.Rows.forEach(element => {
                // console.log("element",element)
                var valorInicial = element.precio1

                // getClientes.precioutil
                switch (this.getClientes.precioutil) {
                case '1':
                  element.precio1 = element.precio1
                  break

                case '2':
                  element.precio1 = element.precio2
                  break

                case '3':
                  element.precio1 = element.precio3
                  break

                case '4':
                  element.precio1 = element.precio4
                  break

                case '5':
                  element.precio1 = element.precio5
                  break

                default:
                  element.precio1 = element.precio1
                  break
                }

                if (element.precio1 == '') {
                  element.precio1 = valorInicial
                }

                var impuesto = 1
                if (this.getArt.impuesto1 > 0) {
                  impuesto = (1 + (this.getArt.impuesto1 / 100))
                }

                if (this.getArt.divisaart == 'D' && divisatienda == 'P') {
                  element.preciopub = parseFloat(element.precio1) * impuesto * tctienda
                  element.precio1 = parseFloat(element.precio1) * tctienda
                } else if (this.getArt.divisaart == 'P' && divisatienda == 'D') {
                  element.preciopub = (parseFloat(element.precio1) * impuesto) / tctienda
                  element.precio1 = parseFloat(element.precio1) / tctienda
                } else {
                  // MISMA DIVISA
                  element.preciopub = (parseFloat(element.precio1) * impuesto)
                }

                var descuento = 0
                if (this.getArt.Pjedesc > 0) {
                  descuento = accounting.unformat(this.getArt.preciopub) * (this.getArt.Pjedesc / 100)
                }

                // console.log("DIVIA DEL ARTICULO",this.getArt.divisaart)

                // console.log(element)
                this.unidades.push({
                  unidad: element.unidad,
                  precio1: element.precio1,
                  preciopub: accounting.formatNumber(element.preciopub, 2),
                  numart: element.numart
                })
              })
              // console.log("UNIDADES",this.unidades)
            }
          }).catch(err => { console.log(err) })

          this.select.unidad = this.unidad
          this.select.numart = this.numart

          var LineaSel = { numlin: response.Linea }
          this.articulos = []

          // this.traerArticulosxLinea(LineaSel).then(respuesta => {
          //   // console.log("RELACIONADOS",respuesta)
          //   // console.log (respuesta[0].numart)
          //    respuesta.forEach(element => {
          //     element.Preciopub = accounting.formatNumber(element.Preciopub, 2)
          //     this.articulos.push(element)
          //   })
          //   // console.log(this.articulos)
          // }).catch(err => {console.log(err)})
        }).catch(err => { console.log(err) })
      }).catch(err => { console.log(err) })
    },

    seluni () {
      console.log(this.unidad)
    },

    // ver art
    info (numart) {
      var NumArt = numart.trim()
      this.$router.push({ name: 'verartinsignia', params: { numart: NumArt } }).catch(err => {})
    },

    arriba () {
      window.scrollTo(500, 0)
    },

    // SUMA UN PRODUCTO más
    add () {
      // console.log("add",this.cantidad)

      if (this.getModo == 'vendedor') {
        if (this.$store.state.Login.cliente == '') {
          this.msg = 'Selecciona un cliente antes de agregar cualquier articulo'
          this.snackbar = true
        } else {
          this.cantidad = this.cantidad + 1.00
        }
      } else {
        this.cantidad = this.cantidad + 1.00
      }
    },

    // RESTA un producto menos.
    remove () {
      // console.log("remove",this.cantidad)

      if (this.getModo == 'vendedor') {
        // MODO VENDEDOR
        if (this.$store.state.Login.cliente == '') {
          this.msg = 'Selecciona un cliente antes de agregar cualquier articulo'
          this.snackbar = true
        } else {
          if (this.cantidad <= 1.00) {
            this.cantidad = 1.00
          } else {
            this.cantidad = this.cantidad - 1.00
          }
        }
      } else {
        // MODO NORMAL
        if (this.cantidad <= 1.00) {
          this.cantidad = 1.00
        } else {
          this.cantidad = this.cantidad - 1.00
        }
      }
    },

    // CUAL ES OBJETIVO DE ESTE METODO? ???
    traerArt () {
      var url = this.$http.options.root.replace('tienda3', 'fotossait/')

      // console.log('this.getArt', this.getArt)

      // this.foto = this.getArt.foto
      // this.fotos = this.getArt.fotos

      this.nomart = this.getArt.details
      this.items = []
      this.obs = this.getArt.obs
      // PARA CAMBIO DE Und
      this.precioneto = this.getArt.preciopub
      this.precio1 = this.getArt.Precio1

      // console.log("precioneto",this.getArt.preciopub )
      // console.log("precioneto",this.getArt )

      // calculo de precio bajo
      var nPrecioPub = accounting.unformat(this.getArt.preciopub)
      var nDescuento = this.getArt.pjedesc
      var nPreciobajo = nPrecioPub * (1 - (nDescuento / 100))
      this.preciobajo = accounting.formatNumber(nPreciobajo, 2)

      // QUe es img por que no se llama como debe? ... o lo que es?
      // console.log("getArt: ",this.getArt)

      if (this.getArt.img == '') {
        this.items.push({ src: '' })
      } else {
        this.items.push({ src: this.getArt.img })
      }

      this.tablacarac = [
        { name: 'Clave', valor: this.getArt.numart },
        { name: 'Unidad', valor: this.getArt.unidad },
        { name: 'Tamaño (cm)', valor: this.getArt.altura + ' x ' + this.getArt.ancho + ' x ' + this.getArt.largo },
        { name: 'Marca', valor: this.getArt.marca },
        { name: 'Modelo', valor: this.getArt.modelo },
        { name: 'Línea', valor: this.getArt.linea },

        { name: 'Peso', valor: this.getArt.peso },
        { name: 'Categoría', valor: this.getArt.categoria },
        { name: 'Youtube Link', valor: this.getArt.youtube_link }
      ]

      // BBJa32lCaaY
      // this.videoId = this.getArt.video.substr(-11)
      this.videoId = this.getArt.video.substr(-11)
    
      // Expresión para poner todo el texto y ver los cambios de linea.
      this.carrusel2 = encodeURIComponent(this.getArt.fotos).split('%0D')

      var filtered = this.carrusel2.filter(function (el) {
        return el != ''
      })

      this.carrusel = filtered
      // console.log("CARRUSEL", this.carrusel)
      this.carrusel.forEach(element => {
        if (element != '') {
          this.items.push({ src: url + element })
        }
      })

      // DETALLES ?? por que no se llaman las cosas por su nombre.
      this.detalles = this.getArt.obs

      // TERMINO DE CARGAR
      this.cargando = false
    },

    validar () {
      if (this.getModo == 'vendedor') {
        if (this.getCliente == '') {
          this.msg = 'Selecciona un cliente antes de agregar cualquier articulo'
          this.snackbar = true
        } else {
          this.agregar()
        }
      } else {
        this.agregar()
      }
    },

    // Agregar producto al carrito
    agregar () {
      this.getArt.cantidad = this.myCant
      // console.log(this.getArt)

      if (this.getCarrito.length == 0) {
        this.getArt.cantidad = this.myCant
      } else {
        for (var i = this.getCarrito.length - 1; i >= 0; i--) {
          if (this.getArt.clave == this.getCarrito[i].clave) {
            console.log('cantidad', this.cantidad)
            this.getArt.cantidad = this.getCarrito[i].cantidad + this.cantidad
          }
        }
      }

      var nUndAdic = this.unidades.length
      // console.log(nUndAdic)
      if (nUndAdic > 1) {
        // console.log("UNIDAD ADD", this.select.unidad, this.select.precio1)
        // this.getArt.unidad = this.select.unidad
        this.getArt.preciopub = this.precioneto
        this.getArt.precio = this.precio1
        this.getArt.precio1 = this.precio1
        this.getArt.unidad = this.select.unidad
      }

      // Agrega la partida al vuex
      // console.log(this.getArt.cantidad, this.getArt.unidad)
      this.carritoadd(this.getArt).then(response => {
        this.$store.state.carrito.carritovisible = true
      })
    }
  }

}
</script>
