<template>
  <v-container>
    <v-card class="elevation-0">
      <v-row>
        <v-col cols="12" xl="6" lg="6" md="6" sm="10" xs="12">
          <v-card-title primary-title>
            <h3>Instrucciones</h3>
          </v-card-title>
          <v-card class="elevation-0">
            <ol align="justify">
              <li> Debes seleccionar el cliente.</li>
              <li>En este panele tienes tu historial de pedidos y cotizaciones.</li>
              <li>Elabora cotizaciones y pedidos par tus cliente</li>
            </ol>
            </v-card>
        </v-col>
        <v-col cols="12" xl="6" lg="6" md="6" sm="10" xs="12">
          <v-card-title primary-title>
            <h3>Selecciona un cliente</h3>
          </v-card-title>

          <v-card-text>
            <v-autocomplete
              loading
              @keyup.enter="submit"
              label="Selecciona un cliente"
              v-model="cliente"
              :items="clintesArr"
              clearable
              append-icon="search"
              @input="evento(cliente)"
          ></v-autocomplete>

          </v-card-text>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xl="6" lg="6" md="6" sm="10" xs="12">
          <Cotizaciones/>
        </v-col>
        <v-col cols="12" xl="6" lg="6" md="6" sm="10" xs="12">
          <Pedidos/>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import store from '@/store'
// import Promociones from '@/components/Promociones.vue'
import Cotizaciones from '@/views/vendedor/MiniCoti'
import Pedidos from '@/views/vendedor/MiniPedido'

export default {
  components: {
    // Promociones,
    Cotizaciones,
    Pedidos
  },
  data: () => ({
    buscando: '',
    clientes: [],
    items: [],
    cliente: '',
    clintesArr: [],
    search: '',
    clientesAll: []
  }),

  created () {
    this.traerTC().then(response => {
      var payload = { divisa: this.$store.state.tipomoneda, tc: this.getTC }

      if (this.getArticulos == '') {
        this.traerAllArticulos(payload).then(response => {
        })
      }

      if (this.$store.state.tipomoneda == 'D') {
        this.moneda = true
      } else {
        this.moneda = false
      }
    })
  },

  computed: {
    ...mapGetters('carrito', ['getCarrito', 'getTC']),
    ...mapGetters('articulos', ['getArticulos']),
    ...mapGetters('Login', ['getModo'])
  },

  methods: {
    ...mapActions('carrito', ['carritoadd', 'traerTC', 'limpiarCarrito']),
    ...mapActions('articulos', ['traerAllArticulos']),
    ...mapActions('Login', ['updateCliente']),

    ver (NumArt) {
      this.$router.push({ name: 'verart', params: { numart: NumArt } })
    },

    agregar (articulo) {
      articulo.cantidad = articulo.cantidad + 1

      this.carritoadd(articulo).then(response => {
        this.snackbar = true
        for (var i = this.getCarrito.length - 1; i >= 0; i--) {
          for (var j = this.items.length - 1; j >= 0; j--) {
            if (this.items[j].clave == this.getCarrito[i].clave) {
              this.items[j].cantidad = 1
            }
          }
        }
        this.$store.state.carrito.carritovisible = true
      })
    },

    // BUSCAR CLIENTE
    submit () {
      // ELIMINANDO BUSQUEDAS VACIAS
      if (this.buscando == null) return
      // console.log("a Buscar", this.buscando)
      var payload = {
        Buscacli: this.buscando.trim()
      }
      this.loading = true

      console.log('payload', payload)
      // tienda3/api/v2/clientes.find
      this.$http.post('api/v3/clientes.find', payload).then(response => {
        console.log(response)
        this.loading = false

        if (response.data.Total > 0) {
          this.clientes = response.data.Data
          this.longitud = response.data.Total

          // pasar el focus
          // this.setFocus()
        } else { this.clientes = [] }
      }).catch(error => {
        this.clientes = []
        this.loading = false
        console.log()
      })
    },

    // this.clintesArr = []
    // this.clientesAll = []
    // var payload = { Buscacli : this.cliente}
    // this.$http.post('auth/api/v1/clientes.find',payload).then(response=>{
    //   this.clientesAll = response.body
    //   for (var i = response.body.length - 1; i >= 0; i--) {
    //     this.clintesArr.push(response.body[i].nomcli)
    //   }
    // })
    // },

    evento (valor) {
      for (var i = this.clientesAll.length - 1; i >= 0; i--) {
        if (this.clientesAll[i].nomcli === valor) {
          this.$http.get('auth/api/v1/clientes/' + this.clientesAll[i].idweb).then(response => {
            console.log('updateCliente', response.body)
            this.updateCliente(response.body)
            var value = []
            this.limpiarCarrito(value)
          })
        }
      }
    }

  }

}
</script>
