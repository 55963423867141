import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '@/router'
import vuetify from '@/plugins/vuetify'

export default {
  namespaced: true,
  state: {
    login: false,
    datosUsuario: '',
    idusuariosweb: '',
    clientes: [],
    cliente: '',
    vendedor: '',
    tipousuario: '',
    cia: ''
  },

  mutations: {
    LOGEADO (state, value) {
      state.login = value
    },

    DATOS_USUARIO (state, datosUsuario) {
      		state.datosUsuario = datosUsuario
    },

    ID_USUARIO (state, idusuariosweb) {
      state.idusuariosweb = idusuariosweb
    },

    CLIENTES_USER (state, clientes) {
      state.clientes = clientes
    },

    UPDATE_CLIENTE (state, cliente) {
      state.cliente = cliente
    },

    INFOVEN (state, infovend) {
      state.vendedor = infovend
    },

    TIPOUSUARIO (state, tipousuario) {
      state.tipousuario = tipousuario
    },

    EMPRESA (state, cia) {
      state.cia = cia
    },

    SALIR (state) {
      state.login = false
      state.datosUsuario = ''
      state.idusuariosweb = ''
      state.clientes = []
      state.cliente = ''
      state.vendedor = ''
      state.tipousuario = ''
    }
  },

  actions: {
    // Valida si el usario existe en la BD
    validarUser ({ commit }, usuario) {
      return new Promise((resolve, reject) => {
			 	// console.log (usuario)
        Vue.http.post('login', usuario).then(respuesta => {
				  	return respuesta.json()
        }).then(respuestaJson => {
		          	// console.log('ValidaUser',respuestaJson)
          if (respuestaJson == null) {
            resolve(false)
          } else {
            resolve(respuestaJson)
    	    	}
		      	}, error => {
		        	reject(error)
		      	})
      })
    },

    GetInfoUser ({ commit, dispatch }, usuario) {
      return new Promise((resolve, reject) => {
        Vue.http.post('api/v1/buscarusuario', usuario).then(response => {
			  	// console.log("buscarusuario", response.data)
          if (response != null) {
            // iniciamos la sesión
            commit('LOGEADO', true)
            // Guardamos los datos del usuario
	        	commit('DATOS_USUARIO', response.data)
			      dispatch('guardarNivel', response.data.nivel, { root: true })

            // resolve(true)
            // Modo cliente
            if (response.body.numcli != 0) {
              // dispatch('traerCientes', response.body.numcli)
              // Traer clientes
              Vue.http.get('auth/api/v1/clientebynumcli/' + response.body.numcli).then(response => {
							  	// console.log(response)
							  	if (typeof (response) === 'object') {
						  			const cliente = response.data
							      commit('CLIENTES_USER', cliente)
							      // console.log('updateCliente', cliente)
		                commit('UPDATE_CLIENTE', cliente)
						      	commit('TIPOUSUARIO', 'cliente')
						      	resolve(true)
							  	}
						  	}, error => {
						    	console.log(error)
						  })
            }

            // Modo vendedor
            else if (response.body.idvend != 0) {
              commit('TIPOUSUARIO', 'vendedor')
              dispatch('infovendedor', response.body.idvend)
              router.push({ name: 'homevendedor' }).catch(err => { console.log(err) })
              resolve(true)
            } else {
              router.push({ name: 'home' }).catch(err => { console.log(err) })
            }
      		}

		      	// Si no se encuentra la información regresamos false para poder decir que no existe el usuario
	      	else {
	      		resolve(false)
	      	}
	    	}, error => {
	      	resolve(false)
	    	})
      })
    },

    actualizaCliente ({ commit }, numcli) {
		    Vue.http.get('auth/api/v1/clientebynumcli/' + numcli).then(response => {
			  	// console.log('actualizaCliente',response.body)
			  	if (typeof (response) === 'object') {
			      	commit('CLIENTES_USER', response.body)
			      	commit('TIPOUSUARIO', 'cliente')
        }
		  	}, error => {
		    	console.log(error)
		  	})
    },

    infoCIA ({ commit }, numcli) {
      Vue.http.get('api/v1/cia.list').then(respuesta => {
				  commit('EMPRESA', respuesta.body)
      }, error => {
			    console.log(error)
      })
    },

    updateCliente ({ dispatch, commit }, cliente) {
      console.log('updateCliente', cliente)
      commit('UPDATE_CLIENTE', cliente)
    },

    infovendedor ({ commit }, idvend) {
      Vue.http.get('auth/api/v1/vend/' + idvend).then(response => {
        commit('INFOVEN', response.body)
        commit('TIPOUSUARIO', 'vendedor')
        // router.push({name:'homevendedor'}).catch(err => {console.log(err)})
      })
    },

    salirLogin ({ commit }) {
      commit('SALIR')
    }
  },

  getters: {
    getLogeado (state) {
		  return state.login
    },
    getdatosUsuario (state) {
      return state.datosUsuario
    },

    getidUsuariosWeb (state) {
      return state.idusuariosweb
    },

    getClientes (state) {
      return state.clientes
    },

    getCliente (state) {
      return state.cliente
    },

    getInfoVend (state) {
      return state.vendedor
    },

    getModo (state) {
      return state.tipousuario
    },

    getCIA (state) {
      return state.cia
    }
  }
}
